.topNav {
  button {
    text-transform: none;
  }
}


.main-content {
  margin-top: 7px;
  border-radius: 5px;
  padding: 10px;
  height: calc(100vh - 100px);
  display: flex;
  overflow: auto;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

  .content {
    width: 100%;
    padding: 10px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.wordForm {
  .label {
    font-size: 15px;
    font-weight: bold;
  }
}

.crossWordtitle {
  border-top: 3px dotted;
  margin-top: 50px;
}

.crossWordContent {
  padding-bottom: 10px;

  .wordsList {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    .chip {
      font-size: 18px;
      font-weight: bold;
      border: 1px solid;
      border-radius: 5px;
      padding: 5px 8px;
    }
  }

  .wordTable {
    margin: 10px 0px;
    border-collapse: collapse;

    /* display: flex;
    justify-content: center; */
    td {
      text-align: center;
      border: 1px solid #040404;
      border-radius: 5px;
      font-size: 20px;
      width: 40px;
      height: 40px;
    }
  }
}


.snakeLadder {
  .custom-form {
    width: 150px;
  }
}

.snackLadderMainView {
  margin-top: 20px;
  padding-bottom: 10px;

  .content_view {
    margin: 10px 0px;
    position: relative;

    img {
      position: relative;
    }
  }
}



.mt-xs {
  margin-top: 5px;
}

.mt-md {
  margin-top: 10px;
}


@media (max-width: 660px) {
  .formBtnGroup {
    margin-top: 10px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}